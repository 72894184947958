/* 全局样式 */
:root {
  --primary-color: #2c3e50;
  --secondary-color: #7f8c8d;
  --success-color: #2ecc71;
  --info-color: #3498db;
  --warning-color: #f1c40f;
  --danger-color: #e74c3c;
  --purple-color: #9b59b6;
  --background-color: #f8f9fa;
  --border-color: #ddd;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --font-family-code: 'Monaco', 'Menlo', 'Ubuntu Mono', monospace;
  --font-family-base: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

/* 页面容器 */
.page-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  font-family: var(--font-family-base);
}

/* 头部样式 */
.header {
  text-align: center;
  margin-bottom: 2rem;
  padding: 2rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px var(--shadow-color);
}

.header h1 {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  font-weight: 600;
}

.subtitle {
  color: var(--secondary-color);
  font-size: 1.2rem;
  line-height: 1.5;
}

/* 提示容器 */
.tips-container {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px var(--shadow-color);
  margin-bottom: 2rem;
  overflow: hidden;
}

.tips-header {
  background: var(--info-color);
  color: white;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
}

.tips-list {
  padding: 1rem 2rem;
  margin: 0;
}

.tips-list li {
  margin: 0.5rem 0;
  color: var(--primary-color);
}

.close-tips {
  margin-left: auto;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0 0.5rem;
}

/* 格式化器容器 */
.formatter-container {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px var(--shadow-color);
  padding: 2rem;
  margin-bottom: 2rem;
  flex-grow: 1;
}

/* 工具栏 */
.toolbar {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  justify-content: space-between;
}

.toolbar-group {
  display: flex;
  gap: 0.5rem;
}

/* 按钮样式 */
.btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.2s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 6px var(--shadow-color);
}

.btn:active {
  transform: translateY(0);
}

.btn .icon {
  font-size: 1rem;
}

.format { background: var(--success-color); color: white; }
.format:hover { filter: brightness(110%); }

.compress { background: var(--info-color); color: white; }
.compress:hover { filter: brightness(110%); }

.copy { background: var(--purple-color); color: white; }
.copy:hover { filter: brightness(110%); }

.clear { background: var(--danger-color); color: white; }
.clear:hover { filter: brightness(110%); }

.sample { background: var(--warning-color); color: var(--primary-color); }
.sample:hover { filter: brightness(110%); }

/* 视图模式按钮 */
.view-mode-buttons {
  display: flex;
  gap: 0.5rem;
}

.view-mode-buttons .btn {
  background: var(--background-color);
  color: var(--primary-color);
  border: 1px solid var(--border-color);
}

.view-mode-buttons .btn.active {
  background: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
}

/* 编辑器容器 */
.editor-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-bottom: 2rem;
  min-height: 600px;
}

/* JSON 输入框 */
.json-input {
  width: 100%;
  height: 100%;
  min-height: 600px;
  padding: 1.5rem;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-family: var(--font-family-code);
  font-size: 0.9rem;
  line-height: 1.6;
  resize: vertical;
  background: var(--background-color);
  transition: all 0.2s ease;
}

.json-input:focus {
  outline: none;
  border-color: var(--info-color);
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
}

/* 错误消息 */
.error-message {
  color: var(--danger-color);
  background: rgba(231, 76, 60, 0.1);
  padding: 1rem;
  border-radius: 6px;
  margin: 1rem 0;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* JSON 树视图 */
.json-tree {
  padding: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  background: var(--background-color);
  overflow: auto;
  height: 100%;
}

/* 语法高亮 */
.syntax-highlighter {
  margin: 0 !important;
  padding: 1rem !important;
  border-radius: 6px !important;
  height: 100% !important;
  overflow: auto !important;
}

/* 响应式设计 */
@media (max-width: 1200px) {
  .page-container {
    padding: 1rem;
  }
  
  .formatter-container {
    padding: 1.5rem;
  }
}

@media (max-width: 992px) {
  .toolbar {
    flex-direction: column;
  }
  
  .toolbar-group {
    justify-content: center;
  }
  
  .view-mode-buttons {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .editor-container {
    grid-template-columns: 1fr;
  }
  
  .header h1 {
    font-size: 2rem;
  }
  
  .btn {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
  }
}

/* 暗色主题支持 */
@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #1a1a1a;
    --primary-color: #ffffff;
    --secondary-color: #a0a0a0;
    --border-color: #333;
    --shadow-color: rgba(0, 0, 0, 0.3);
  }
  
  .header,
  .tips-container,
  .formatter-container {
    background: #2d2d2d;
  }
  
  .json-input,
  .json-tree {
    background: #1a1a1a;
    color: #ffffff;
  }
  
  .view-mode-buttons .btn {
    background: #2d2d2d;
    color: #ffffff;
  }
}

/* 使用指南样式 */
.guide-container {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px var(--shadow-color);
  margin: 2rem 0;
  padding: 2rem;
  color: var(--primary-color);
}

.guide-section {
  margin-bottom: 3rem;
}

.guide-section:last-child {
  margin-bottom: 0;
}

/* 标题样式 */
.guide-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 4px solid var(--info-color);
}

.guide-section-title {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--primary-color);
  margin: 2rem 0 1rem;
  padding-left: 1rem;
  border-left: 4px solid var(--success-color);
}

.guide-subsection-title {
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--primary-color);
  margin: 1.5rem 0 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.guide-subsection-title::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: var(--info-color);
  border-radius: 50%;
}

/* 内容样式 */
.guide-content {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--secondary-color);
  margin-bottom: 1.5rem;
}

/* 功能列表 */
.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin: 1.5rem 0;
}

.feature-item {
  background: var(--background-color);
  padding: 1.5rem;
  border-radius: 8px;
  border-left: 4px solid var(--info-color);
  transition: transform 0.2s ease;
}

.feature-item:hover {
  transform: translateY(-2px);
}

.feature-title {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.feature-icon {
  color: var(--info-color);
  font-size: 1.4rem;
}

.feature-description {
  color: var(--secondary-color);
  line-height: 1.5;
}

/* 快捷键列表 */
.shortcuts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  margin: 1.5rem 0;
}

.shortcut-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem;
  background: var(--background-color);
  border-radius: 6px;
  transition: transform 0.2s ease;
}

.shortcut-item:hover {
  transform: translateX(5px);
}

.shortcut-keys {
  display: flex;
  gap: 0.5rem;
}

.key {
  background: white;
  color: var(--primary-color);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-family: var(--font-family-code);
  font-size: 0.9rem;
  border: 1px solid var(--border-color);
  box-shadow: 0 2px 0 var(--border-color);
}

.shortcut-description {
  color: var(--secondary-color);
}

/* 步骤指引 */
.steps-list {
  counter-reset: step;
  margin: 1.5rem 0;
  padding: 0;
  list-style: none;
}

.step-item {
  position: relative;
  padding: 1.5rem 1.5rem 1.5rem 3rem;
  margin-bottom: 1rem;
  background: var(--background-color);
  border-radius: 8px;
  counter-increment: step;
}

.step-item::before {
  content: counter(step);
  position: absolute;
  left: 1rem;
  top: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  background: var(--info-color);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.step-title {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.step-description {
  color: var(--secondary-color);
  line-height: 1.5;
}

/* 提示和警告 */
.guide-tip,
.guide-warning {
  padding: 1rem 1.5rem;
  margin: 1rem 0;
  border-radius: 6px;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.guide-tip {
  background: rgba(52, 152, 219, 0.1);
  border-left: 4px solid var(--info-color);
}

.guide-warning {
  background: rgba(231, 76, 60, 0.1);
  border-left: 4px solid var(--danger-color);
}

.tip-icon,
.warning-icon {
  font-size: 1.2rem;
}

.tip-icon {
  color: var(--info-color);
}

.warning-icon {
  color: var(--danger-color);
}

.tip-content,
.warning-content {
  flex: 1;
  line-height: 1.5;
}

/* 暗色主题适配 */
@media (prefers-color-scheme: dark) {
  .guide-container {
    background: #2d2d2d;
  }

  .feature-item,
  .shortcut-item,
  .step-item {
    background: #1a1a1a;
  }

  .key {
    background: #2d2d2d;
    border-color: #444;
    box-shadow: 0 2px 0 #444;
  }

  .guide-tip {
    background: rgba(52, 152, 219, 0.15);
  }

  .guide-warning {
    background: rgba(231, 76, 60, 0.15);
  }
}

/* 响应式适配 */
@media (max-width: 768px) {
  .guide-title {
    font-size: 2rem;
  }

  .guide-section-title {
    font-size: 1.6rem;
  }

  .guide-subsection-title {
    font-size: 1.3rem;
  }

  .guide-content {
    font-size: 1rem;
  }

  .feature-grid {
    grid-template-columns: 1fr;
  }

  .shortcuts-grid {
    grid-template-columns: 1fr;
  }

  .step-item {
    padding: 1rem 1rem 1rem 2.5rem;
  }

  .step-item::before {
    left: 0.75rem;
    top: 1rem;
  }
}