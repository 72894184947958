.language-switcher {
  position: relative;
  margin: 0.5rem;
  z-index: 1000;
}

.language-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-color);
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  min-width: 120px;
  transition: all 0.2s ease;
}

.language-button:hover {
  border-color: var(--primary-color);
  background-color: var(--hover-color, rgba(0, 0, 0, 0.05));
}

.language-flag {
  font-size: 1.2rem;
}

.language-name {
  flex: 1;
  text-align: left;
  margin: 0 0.5rem;
}

.language-arrow {
  font-size: 0.7rem;
  opacity: 0.7;
}

.language-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 0.25rem;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow-y: auto;
  animation: dropdown 0.2s ease;
}

.language-option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding: 0.5rem 1rem;
  border: none;
  background: none;
  color: var(--text-color);
  cursor: pointer;
  font-size: 0.9rem;
  text-align: left;
  transition: all 0.2s ease;
}

.language-option:hover {
  background-color: var(--hover-color, rgba(0, 0, 0, 0.05));
}

.language-option.active {
  background-color: var(--primary-color-light, rgba(0, 120, 255, 0.1));
  color: var(--primary-color);
}

.language-native {
  flex: 1;
}

.language-english {
  color: var(--text-secondary-color, #666);
  font-size: 0.8rem;
}

@keyframes dropdown {
  from {
    opacity: 0;
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* RTL支持 */
[dir="rtl"] .language-name {
  text-align: right;
}

[dir="rtl"] .language-option {
  text-align: right;
}

/* 暗色主题支持 */
@media (prefers-color-scheme: dark) {
  .language-button:hover,
  .language-option:hover {
    background-color: var(--hover-color-dark, rgba(255, 255, 255, 0.1));
  }

  .language-option.active {
    background-color: var(--primary-color-dark, rgba(0, 120, 255, 0.2));
  }

  .language-english {
    color: var(--text-secondary-color-dark, #999);
  }

  .language-dropdown {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  }
}
