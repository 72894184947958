:root {
  --primary-color: #1a73e8;
  --primary-light: #e8f0fe;
  --primary-dark: #174ea6;
  --background-color: #ffffff;
  --background-secondary: #f8f9fa;
  --text-color: #202124;
  --text-secondary: #5f6368;
  --border-color: #dadce0;
  --error-color: #d93025;
  --success-color: #188038;
  --warning-color: #f29900;
  --hover-color: rgba(26, 115, 232, 0.04);
  --shadow-color: rgba(0, 0, 0, 0.1);
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary-color: #8ab4f8;
    --primary-light: #1a2432;
    --primary-dark: #aecbfa;
    --background-color: #202124;
    --background-secondary: #292a2d;
    --text-color: #e8eaed;
    --text-secondary: #9aa0a6;
    --border-color: #3c4043;
    --error-color: #f28b82;
    --success-color: #81c995;
    --warning-color: #fdd663;
    --hover-color: rgba(138, 180, 248, 0.08);
    --shadow-color: rgba(0, 0, 0, 0.3);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
}

.app-header {
  background-color: var(--background-color);
  border-bottom: 1px solid var(--border-color);
  padding: 0.5rem 1rem;
  position: sticky;
  top: 0;
  z-index: 100;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 2px var(--shadow-color);
}

.app-title {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.app-logo {
  width: 32px;
  height: 32px;
}

main {
  flex: 1;
  padding: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

@media (max-width: 768px) {
  main {
    padding: 1rem;
  }
}

.card {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 1px 3px var(--shadow-color);
  transition: box-shadow 0.3s ease;
}

.card:hover {
  box-shadow: 0 4px 6px var(--shadow-color);
}

.button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.button:hover {
  background-color: var(--primary-dark);
}

.button.secondary {
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.button.secondary:hover {
  background-color: var(--primary-light);
}

.error-message {
  color: var(--error-color);
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.success-message {
  color: var(--success-color);
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid var(--primary-light);
  border-top-color: var(--primary-color);
  border-radius: 50%;
  animation: spinner 0.8s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

/* RTL支持 */
[dir="rtl"] .app-title {
  flex-direction: row-reverse;
}

/* 动画 */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.3s ease;
}

.nav-bar {
  background: #1a237e;
  padding: 15px 0;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.nav-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-logo {
  color: white;
  text-decoration: none;
  font-size: 1.5em;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 5px;
}

.logo-icon {
  color: #64b5f6;
  font-weight: bold;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 4px;
  transition: all 0.3s ease;
  font-weight: 500;
}

.nav-links a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.nav-links a.active {
  background-color: rgba(255, 255, 255, 0.2);
  color: #64b5f6;
}

@media (max-width: 768px) {
  .nav-content {
    flex-direction: column;
    gap: 15px;
  }
  
  .nav-links {
    flex-wrap: wrap;
    justify-content: center;
  }
}
