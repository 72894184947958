.feedback-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  z-index: 1000;
}

.feedback-button:hover {
  background-color: #40a9ff;
  transform: translateY(-2px);
}

.feedback-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.feedback-content {
  background: white;
  padding: 24px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #999;
  padding: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.close-button:hover {
  background-color: #f0f0f0;
  color: #666;
}

.feedback-content h2 {
  margin: 0 0 24px;
  color: #333;
  font-size: 20px;
}

.form-group {
  margin-bottom: 16px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #333;
  font-weight: 500;
}

.form-group textarea,
.form-group input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.form-group textarea {
  resize: vertical;
  min-height: 100px;
}

.form-group textarea:focus,
.form-group input:focus {
  outline: none;
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.rating-container {
  margin-bottom: 16px;
}

.star-rating {
  display: flex;
  gap: 4px;
}

.star {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: #d9d9d9;
  font-size: 24px;
  transition: all 0.3s ease;
}

.star:hover,
.star.active {
  color: #fadb14;
  transform: scale(1.1);
}

.submit-button {
  width: 100%;
  padding: 10px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background-color: #40a9ff;
}

.status-message {
  margin: 16px 0;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
  color: #52c41a;
}

.status-message.error {
  background-color: #fff2f0;
  border-color: #ffccc7;
  color: #ff4d4f;
}

@media (max-width: 480px) {
  .feedback-content {
    width: 95%;
    padding: 16px;
  }

  .star {
    font-size: 20px;
  }

  .feedback-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}
