/* RTL specific styles */
[dir="rtl"] .App {
  text-align: right;
}

[dir="rtl"] .language-selector {
  text-align: right;
}

/* 保持某些元素的LTR方向 */
[dir="rtl"] .json-input,
[dir="rtl"] .json-output {
  direction: ltr;
  text-align: left;
}

/* 调整按钮和控件的方向 */
[dir="rtl"] .controls {
  flex-direction: row-reverse;
}

/* 确保JSON格式化的内容保持正确的显示 */
[dir="rtl"] pre {
  direction: ltr;
  text-align: left;
}

/* 确保数字和代码保持LTR */
[dir="rtl"] code,
[dir="rtl"] .number {
  direction: ltr;
  display: inline-block;
}
